@use "@angular/material" as mat;
@use 'sass:map';

@include mat.core();


$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);

$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);

$blue-palette: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);


$my-app-primary: mat.define-palette(mat.$blue-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-app-primary,
        accent: $my-app-accent,
        warn: $my-app-warn,
      ),
    )
);

@include mat.all-component-themes($my-app-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.flexExpand {
  flex: 1 1 auto;
}
